// ui_utile.js

export function fadeIn(element, duration) {
  let opacity = 0;
  element.style.display = "block";
  const interval = 10; // インターバル時間を設定（ミリ秒）
  const step = interval * (1 / duration); // 透明度が変化するステップを計算
  const timer = setInterval(() => {
    if (opacity >= 1) {
      clearInterval(timer); // 透明度が1に達したらタイマーをクリア
    }
    element.style.opacity = opacity;
    opacity += step; // 次の透明度をセット
  }, interval);
}

export function fadeOut(element, duration) {
  let opacity = 1;
  const interval = 10; // インターバル時間を設定（ミリ秒）
  const step = interval * (1 / duration); // 透明度が変化するステップを計算
  const timer = setInterval(() => {
    if (opacity <= 0) {
      clearInterval(timer); // 透明度が0に達したらタイマーをクリア
      element.style.display = "none"; // 要素を非表示
    }
    element.style.opacity = opacity;
    opacity -= step; // 次の透明度をセット
  }, interval);
}
