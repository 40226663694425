// search_zip.js

// 郵便番号検索
$(function () {
  $('.search_zip').click(function() {
    // 郵便番号 セレクターID
    var zipId = $(this).data("zip");
  
    // 住所 セレクターID
    var addressId_1 = $(this).data("address1");
    var addressId_2 = $(this).data("address2");
    var addressId_3 = $(this).data("address3");
  
    // 郵便番号
    var zipCode = hankaku2Zenkaku($('#'+ zipId).val());
    
    $.getJSON("https://api.zipaddress.net?callback=?",
      {
        zipcode: zipCode
      },
      function( data, status ) {
        var msg = data["message"];
        if ( msg ) {
          $('.zip_error').text(msg);
        } else {
          var address = data;
          $('#' + addressId_1).val(address["pref"]);
          $('#' + addressId_2).val(address["city"]);
          $('#' + addressId_3).val(address["town"]);
        }
      }
    );
  });
});

　var hankaku2Zenkaku = function(str) {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９ - ー‐]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
}
