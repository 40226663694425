// modals.js

// import
import { fadeIn, fadeOut } from './ui_utile.js';

// ページロード時にモーダルを開く
document.addEventListener("DOMContentLoaded", function() {
  // ページロード時にモーダルを開く
  const modal = document.querySelector('.modal.js-modal.onload');
  if (modal) {
    fadeIn(modal, 100);
  }

  // モーダルを閉じる
  const closeModalButtons = document.querySelectorAll('.js-modal-close');
  closeModalButtons.forEach(button => {
    button.addEventListener('click', () => {
      fadeOut(modal, 100);
    });
  });
});
