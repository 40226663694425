import $ from '../modules/jquery_loader';

import '../plugins/slick/slick.min.js';

// modules
import '../modules/forms.js';
import '../modules/modal.js';

// rails utils
import RailsUjs from '@rails/ujs';
RailsUjs.start();


/*==============================================
  #へ移動
==============================================*/
$(function(){
 $('a[href^="#"]').click(function(){
  var speed = 600;
  var href= $(this).attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var position = target.offset().top;
  $("html, body").animate({scrollTop:position}, speed, "swing");
  return false;
 });
});


/*==============================================
  accordionbox
==============================================*/
$(function(){
  $(".ac-box .ac-btn").on("click", function() {
    $(this).next().slideToggle();
    // activeが存在する場合
    if ($(this).children(".ac-icon").hasClass('active')) {
      // activeを削除
      $(this).children(".ac-icon").removeClass('active');
    }
    else {
      // activeを追加
      $(this).children(".ac-icon").addClass('active');
    }
  });
});

$(function () {
  $('.add').on('click', function () {
    /*クリックでコンテンツを開閉*/
    $(this).next().slideToggle(300);
    /*矢印の向きを変更*/
    $(this).toggleClass('open');
  });
});


/*==============================================
  タブ
==============================================*/
$(function() {
  //クリックしたときのファンクションをまとめて指定
  $('.tab-area01 li').click(function() {

    //.index()を使いクリックされたタブが何番目かを調べ、
    //indexという変数に代入します。
    var index = $('.tab-area01 li').index(this);

    //コンテンツを一度すべて非表示にし、
    $('.tab-main .tab-block01').css('display','none');

    //クリックされたタブと同じ順番のコンテンツを表示します。
    $('.tab-main .tab-block01').eq(index).css('display','block');

    //一度タブについているクラスselectを消し、
    $('.tab-area01 li').removeClass('select');

    //クリックされたタブのみにクラスselectをつけます。
    $(this).addClass('select')
  });
});

$(function() {
  //クリックしたときのファンクションをまとめて指定
  $('.tab-area02 li').click(function() {

    //.index()を使いクリックされたタブが何番目かを調べ、
    //indexという変数に代入します。
    var index = $('.tab-area02 li').index(this);

    //コンテンツを一度すべて非表示にし、
    $('.tab-main .tab-block02').css('display','none');

    //クリックされたタブと同じ順番のコンテンツを表示します。
    $('.tab-main .tab-block02').eq(index).css('display','block');

    //一度タブについているクラスselectを消し、
    $('.tab-area02 li').removeClass('select');

    //クリックされたタブのみにクラスselectをつけます。
    $(this).addClass('select')
  });
});


/*==============================================
  sp header背景変更
==============================================*/
$(function() {
  var dist = 250;
  $(window).scroll(function() {
    if ($(window).scrollTop() > dist) {
      $('#header').addClass('thin');
    } else {
      $('#header').removeClass('thin');
    }
  });
});


/*==============================================
  ポップアップ
==============================================*/
$(function(){
    $('.js-modal-open').each(function(){
        $(this).on('click',function(){
            var target = $(this).data('target');
            var modal = document.getElementById(target);
            //PCのみ
            if (window.matchMedia( "(min-width: 769px)" ).matches) {
              $('.g-nav').css('z-index','999');
            }
            //SPのみ
            if (window.matchMedia( "(max-width: 768px)" ).matches) {
              $(' #hb-menu').css('z-index','-1');
              $('.sp-dandori-return').css('z-index','-1');
              $('#main').css('width','100%');
              $('#main').css('position','fixed');
            }
            $(modal).fadeIn();
            return false;
        });
    });
    $('.js-modal-close').on('click',function(){
      //PCのみ
      if (window.matchMedia( "(min-width: 769px)" ).matches) {
        //CSSを元の値にリセット
        $('.g-nav').css('z-index','');
      }
      //SPのみ
      if (window.matchMedia( "(max-width: 768px)" ).matches) {
        //CSSを元の値にリセット
        $('#hb-menu').css('z-index','');
        $('.sp-dandori-return').css('z-index','');
        $('#main').css('width','');
        $('#main').css('position','');
      }
      $('.js-modal').fadeOut();
      return false;
    });
});


/*==============================================
  Zero Menu
==============================================*/
$(function(){
  $("#hb-menu,.overlay").click(function () {
    $("#header").toggleClass("hb-open");

    // メイン画面のスクロールロック
    var $body = document.getElementById("body");
    if ( $("#header").hasClass("hb-open") ) {
      $body.classList.add("fixed");
    } else {
      $body.classList.remove("fixed");
    }
  });

  //アンカーリンクを押したとき(aタグのhref属性の値に「#」が含まれている場合)
  $(".sp-g-nav a[href *= '#']").click(function() {
    $("#header").toggleClass('hb-open');

    // メイン画面のスクロールロック
    var $body = document.getElementById("body");
    if ( $("#header").hasClass("hb-open") ) {
      $body.classList.add("fixed");
    } else {
      $body.classList.remove("fixed");
    }
  });
});


/*==============================================
  スライドショー
==============================================*/
$(function() {
  var resize = $(window).innerWidth();
  //windowサイズでcenterPaddingを変更
  if (resize > 1500) {
    $('.slider').slick({
      arrows: false,
      infinite: true,
      dots: true,
      dotsClass: 'slide-dots',
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      pauseOnFocus: false,
      speed: 1000,
      autoplaySpeed: 3500,
      centerMode: true,
      pauseOnHover: false,
      centerPadding:'15%',
    });
  } else if (resize < 1499) {
    $('.slider').slick({
      arrows: false,
      infinite: true,
      dots: true,
      dotsClass: 'slide-dots',
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      pauseOnFocus: false,
      speed: 1000,
      autoplaySpeed: 3500,
      centerMode: true,
      pauseOnHover: false,
      centerPadding:'3%',
    });
  }
});

/*==============================================
  ファイル添付ボタン
==============================================*/
$(function(){
  $("input[type='file']").on('change',function(){
     var file = $(this).prop('files')[0];
     if(!($(".filename").length)){
       $("#input-group").append('<span class="filename"></span>');
     }
     $("#input-label").addClass('changed');
     $(".filename").html(file.name);
   });
 });


/*==============================================
  途中から固定
==============================================*/
$(function () {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 310) {
      $('#b-fixed').addClass('fixed');
    } else {
      $('#b-fixed').removeClass('fixed');
    }
  });
});
$(function () {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 240) {
      $('#b-fixed02').addClass('fixed');
    } else {
      $('#b-fixed02').removeClass('fixed');
    }
  });
});


/*==============================================
  グロナビを途中から上部に固定(PC)
==============================================*/
$(function() {
  var offset = $('.g-nav').offset();
  $(window).scroll(function () {
    if (offset) {
      if ($(window).scrollTop() > offset.top) {
        $('.g-nav').addClass('fixed');
      } else {
        $('.g-nav').removeClass('fixed');
      }
    }
  });
});
