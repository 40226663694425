// js import
import './common/jQueryRotate.js';
import EXIF from './common/exif.js';
import './common/footable_utils.js';
import './common/select2_utils.js';
import './common/search_zip.js';

// Enter key submit cancel
$(function() {
  $(document).on("keypress", "input:not(.allow_submit)", function(event) {
    return event.which !== 13;
  });
});

// 画像アッププレビュー
$(document).on('change', 'input.preview_image[type=file]', function(){
  var file = this.files[0];
  var fileExt = file.name.split('.');
  var isPdf = fileExt[fileExt.length - 1].toLowerCase() == 'pdf';

  // exif
  var orientation;
  EXIF.getData(file, function(){
    orientation = file.exifdata.Orientation;
  });

  // 読み込み
  var reader = new FileReader();
  reader.readAsDataURL(file);

  var $image = $(this).prev('img.form_image');
  var $pdf = $image.prev('embed.form_pdf');

  // 読み込み後
  reader.onload = function () {
    if (isPdf) {
      $pdf.attr('src', reader.result);
      $pdf.attr('width', '50%');
      $pdf.attr('height', '300px');
      $image.hide();
    } else {
      $pdf.hide();
      $image.attr('src', reader.result);
    }
  };
});

// CSRF-Token
$(function(){
  $.ajaxSetup({
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  });
});

// ２重押下防止
document.addEventListener('DOMContentLoaded', function() {
  // Add event listener to a static parent element
  document.body.addEventListener('click', function(e) {
    // Check if clicked element or its parent has the 'prevent-double-submit' class
    if (e.target.classList.contains('prevent-double-submit') || e.target.closest('.prevent-double-submit')) {
      e.preventDefault();

      var button = e.target.closest('.prevent-double-submit');
      // Disable the button and change the text
      button.disabled = true;
      button.innerText = '送信中...';

      // Submit the form
      button.form.submit();
    }
  });
});
